import React from 'react';
import Footer from '../Footer';
import NavbarSide from '../../components/NavbarSide';
import styled from './index.module.css';
import SEO from '../SEO';

const Layout = (props) => {
  return (
    <div className={styled.wrapper}>
      <SEO />
      <div className={styled.nav}>
        <NavbarSide />
      </div>
      <div className={styled.rest}>
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
