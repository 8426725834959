import React from 'react';

const IconBlog = ({ styling }) => {
  return (
    <svg className={styling || ''} viewBox="0 0 195 176" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M78.7432 27.4698C78.7432 23.3277 82.101 19.9698 86.2432 19.9698H184.908C189.05 19.9698 192.408 23.3277 192.408 27.4698V101.001C192.408 105.143 189.05 108.501 184.908 108.501H172.658C168.104 108.501 163.909 110.979 161.711 114.967L161.595 115.178C159.389 119.18 154.148 120.296 150.503 117.541L141.891 111.03C139.721 109.389 137.074 108.501 134.353 108.501H86.2432C82.101 108.501 78.7432 105.143 78.7432 101.001V27.4698Z"
        strokeWidth="5"
      />
      <path
        d="M128.717 10.9305C128.717 6.78835 125.359 3.43048 121.217 3.43048H31.2699C27.1277 3.43048 23.7699 6.78834 23.7699 10.9305V84.4618C23.7699 88.6039 27.1278 91.9618 31.2699 91.9618H41.6008C46.0732 91.9618 50.1682 94.4687 52.2023 98.4518C54.2027 102.369 59.3073 103.442 62.7153 100.661L69.9272 94.7767C72.1585 92.9561 74.9499 91.9618 77.8296 91.9618H121.217C125.359 91.9618 128.717 88.6039 128.717 84.4618V10.9305Z"
        strokeWidth="5"
      />
      <path
        d="M108.118 45.9092C108.118 41.7671 104.76 38.4092 100.618 38.4092H10.6708C6.52863 38.4092 3.17078 41.7671 3.17078 45.9092V119.441C3.17078 123.583 6.52864 126.941 10.6708 126.941H21.0017C25.4741 126.941 29.5691 129.447 31.6031 133.431C33.6035 137.348 38.7082 138.421 42.1162 135.64L49.3281 129.755C51.5594 127.935 54.3508 126.941 57.2305 126.941H100.618C104.76 126.941 108.118 123.583 108.118 119.441V45.9092Z"
        strokeWidth="5"
      />
      <path
        d="M66.2783 76.9698C66.2783 72.8277 69.6362 69.4698 73.7783 69.4698H172.443C176.585 69.4698 179.943 72.8277 179.943 76.9698V150.501C179.943 154.643 176.585 158.001 172.443 158.001H160.193C155.639 158.001 151.444 160.479 149.246 164.467L149.13 164.678C146.924 168.68 141.684 169.796 138.039 167.041L129.427 160.53C127.256 158.889 124.609 158.001 121.888 158.001H73.7783C69.6362 158.001 66.2783 154.643 66.2783 150.501V76.9698Z"
        strokeWidth="5"
      />
      <line x1="92.3726" y1="92.1585" x2="153.848" y2="92.1585" strokeWidth="5" strokeLinecap="round" />
      <line x1="45.5059" y1="23.0932" x2="106.981" y2="23.0932" strokeWidth="5" strokeLinecap="round" />
      <line x1="92.3726" y1="111.151" x2="153.848" y2="111.151" strokeWidth="5" strokeLinecap="round" />
      <line x1="92.3726" y1="131.871" x2="153.848" y2="131.871" strokeWidth="5" strokeLinecap="round" />
    </svg>
  );
};

export default IconBlog;
