import React, { useState } from 'react';
import styled from './index.module.css';
import { Link } from 'gatsby';
import Image from '../../images/6_800px.jpg';
import ImageDecorSidenav from '../../images/imageComponents/ImageDecorSidenav';
import IconProduct from '../../images/imageComponents/IconProduct';
import IconBlog from '../../images/imageComponents/IconBlog';
import IconLetter from '../../images/imageComponents/IconLetter';
import IconHome from '../../images/imageComponents/IconHome';
import IconHamburger from '../../images/imageComponents/IconHamburger';

const Navbar = () => {
  const [show, setShow] = useState(false);

  const navElements = [
    { link: '/', icon: <IconHome styling={styled.icon} /> },
    { link: '/products', icon: <IconProduct styling={styled.icon} /> },
    { link: '/blog', icon: <IconBlog styling={styled.icon} /> },
    { link: '/contact', icon: <IconLetter styling={styled.icon} /> },
  ];

  return (
    <header className={`${styled.navbarFull} ${show ? styled.navbarFullOut : ''}`}>
      <button
        type="button"
        className={`${styled.btnBurger} ${show ? styled.btnBurgerActive : ''}`}
        onClick={() => setShow(!show)}
      >
        <IconHamburger styling={styled.IconHamburger} />
      </button>
      <div className={styled.linkWrapper}>
        <div className={styled.navbarLinks}>
          <nav className={styled.navElements}>
            {navElements.map((element, index) => {
              return (
                <div className={styled.singleNavElement} key={`navElement-${index}`}>
                  <Link className={styled.list} to={element.link} activeClassName={styled.activePageLink}>
                    {element.icon}
                  </Link>
                </div>
              );
            })}
          </nav>
        </div>
      </div>
      <div className={styled.decWrapper}>
        {show ? (
          <>
            <ImageDecorSidenav styling={styled.decor} imageFill={Image} />
          </>
        ) : null}
      </div>
    </header>
  );
};

export default Navbar;
