import React from 'react';

const Hamburger = ({ styling }) => {
  return (
    <svg className={styling || ''} viewBox="0 0 219 219" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle cx="109.874" cy="105.205" r="85" fill="#CFAC94" />
      </g>
      <circle cx="116.957" cy="114.757" r="84.5" stroke="#382E26" />
      <g filter="url(#filter1_d)">
        <rect
          x="80.6643"
          y="109.078"
          width="71.521"
          height="12.4284"
          rx="6.21422"
          transform="rotate(-10.6602 80.6643 109.078)"
          fill="#F6F4ED"
        />
      </g>
      <g filter="url(#filter2_d)">
        <path
          d="M85.1849 134.094C84.5501 130.722 86.7696 127.473 90.1424 126.838L148.215 115.907C151.588 115.272 154.837 117.491 155.472 120.864V120.864C156.106 124.237 153.887 127.486 150.514 128.121L92.4414 139.052C89.0687 139.687 85.8198 137.467 85.1849 134.094V134.094Z"
          fill="#F6F4ED"
        />
      </g>
      <g filter="url(#filter3_d)">
        <rect
          x="87.7478"
          y="147.71"
          width="71.521"
          height="12.4284"
          rx="6.21422"
          transform="rotate(-10.6602 87.7478 147.71)"
          fill="#F6F4ED"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.873535"
          y="0.204712"
          width="218"
          height="218"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="11.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="72.6643"
          y="91.8481"
          width="88.5857"
          height="41.4442"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter2_d"
          x="76.0354"
          y="110.757"
          width="88.5857"
          height="41.4442"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter3_d"
          x="79.7478"
          y="130.479"
          width="88.5857"
          height="41.4442"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default Hamburger;
