import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useSiteMetadata } from '../../hooks/siteMetadata';
import { seoTypes } from './seo';

const SEO = ({ title, article, description, image }: seoTypes): JSX.Element => {
  const { pathname } = useLocation();
  const { siteMetadata } = useSiteMetadata();

  // TODO: verify these are the correct values...
  const seo = {
    title: title ? title : pathname.split('/').pop(),
    description: description ? description : siteMetadata.defaultDescription,
    image: `${siteMetadata.siteUrl}${image ? image : siteMetadata.defaultImage}`,
    url: `${siteMetadata.siteUrl}${pathname}`,
    article: article ? article : false,
  };

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url ? <meta property="og:url" content={seo.url} /> : null}
      {seo.article ? <meta property="og:type" content="article" /> : null}
      {seo.title ? <meta property="og:title" content={seo.title} /> : null}
      {seo.description ? <meta property="og:description" content={seo.description} /> : null}
      {seo.image ? <meta property="og:image" content={seo.image} /> : null}
    </Helmet>
  );
};

export default SEO;
