import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = (): siteMetadataProps => {
  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl: url
          defaultImage: image
          author: author
        }
      }
    }
  `);
  return site;
};

export type siteMetadataProps = {
  siteMetadata: {
    defaultTitle: string;
    defaultDescription: string;
    siteUrl: string;
    defaultImage: string;
    author: string;
  };
};
