import React from 'react';

const IconLetter = ({ styling }) => {
  return (
    <svg className={styling || ''} viewBox="0 0 171 171" xmlns="http://www.w3.org/2000/svg">
      <circle cx="85.7214" cy="85.7028" r="85" stroke="none" />
      <path
        d="M30.3152 45.5856H141.128C145.27 45.5856 148.628 48.9434 148.628 53.0856V118.32C148.628 122.462 145.27 125.82 141.128 125.82H30.3152C26.1731 125.82 22.8152 122.462 22.8152 118.32V53.0856C22.8152 48.9434 26.1731 45.5856 30.3152 45.5856Z"
        strokeWidth="1"
      />
      <path
        d="M20.3152 43.0856L52.8343 72.9949M151.128 43.0856L118.241 72.9949M151.128 128.32L118.241 72.9949M20.3152 128.32L52.8343 72.9949M52.8343 72.9949L78.6218 96.7129C82.4328 100.218 88.289 100.234 92.1195 96.7507L118.241 72.9949"
        strokeWidth="1"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconLetter;
