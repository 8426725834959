import React from 'react';

const IconHome = ({ styling }) => {
  return (
    <svg className={styling || ''} viewBox="-10 0 200 146" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.77002 48.1948C2.77002 45.3357 4.39558 42.7253 6.96144 41.464L84.236 3.47887C86.2957 2.46642 88.7056 2.45274 90.7766 3.44176L170.484 41.5059C173.092 42.7512 174.752 45.384 174.752 48.2738V135.964C174.752 140.107 171.394 143.464 167.252 143.464H115.474C111.332 143.464 107.974 140.107 107.974 135.964V106.321C107.974 105.575 107.911 104.711 107.654 103.82C107.155 102.089 105.825 98.4445 102.741 95.2017C99.5891 91.8872 94.7204 89.1238 87.4816 89.1238C73.0512 89.1238 68.0867 99.9346 66.8543 103.517C66.4903 104.575 66.3961 105.615 66.3961 106.53V135.964C66.3961 140.107 63.0382 143.464 58.8961 143.464H10.27C6.12789 143.464 2.77002 140.107 2.77002 135.964V48.1948Z"
        strokeWidth="5"
      />
    </svg>
  );
};

export default IconHome;
