import React from 'react';

const iconProduct = ({ styling }) => {
  return (
    <svg className={styling || ''} viewBox="0 0 183 101" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.01831" y="3.0072" width="177.389" height="9.9909" rx="4.99545" strokeWidth="5" />
      <rect x="126.075" y="88.0072" width="20.0158" height="9.9909" rx="4.99545" strokeWidth="5" />
      <rect x="41.7273" y="88.0072" width="20.0158" height="9.9909" rx="4.99545" strokeWidth="5" />
      <path
        d="M19.311 20.4981C19.311 16.356 22.6689 12.9981 26.811 12.9981H156.615C160.757 12.9981 164.115 16.356 164.115 20.4981V42.4093V83.0026C164.115 87.1448 160.757 90.5026 156.615 90.5026H26.811C22.6689 90.5026 19.311 87.1448 19.311 83.0026V42.4093V20.4981Z"
        strokeWidth="5"
      />
      <rect x="80.3726" y="60.1025" width="20.0158" height="9.9909" rx="4.99545" strokeWidth="5" />
      <rect x="81.7051" y="22.5117" width="20.0158" height="9.9909" rx="4.99545" strokeWidth="5" />
    </svg>
  );
};

export default iconProduct;
