import * as React from 'react';
import { Link } from 'gatsby';
import styled from './index.module.css';
import { useSiteMetadata } from '../../hooks/siteMetadata';

const Footer = (): JSX.Element => {
  const { siteMetadata } = useSiteMetadata();

  return (
    <footer className={styled.footer}>
      <p>© {siteMetadata.author}</p>
      <div>
        <Link className={styled.link} to="/privacy">
          Privacy Policy
        </Link>
        <Link className={styled.link} to="/terms">
          Terms of Use
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
